import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ContactForm from "../components/contactForm"

const AktywnyPage = () => (
  <Layout>
    <SEO title="Kontakt - Weekend w Gdańsku" />
    <section className="single-trip-description contact">
      <div className="wrap">
        <div className="">
          <div>
            <ContactForm />
          </div>
        </div>
        <div>
          <h3>Zapraszamy do kontaktu </h3>
          <h1>Biuro Turystyczne Fifitravel</h1>
          <p>
            Jeśli masz historię, którą chciałbyś się z nami podzielić lub
            pytanie, na które nie udzielono odpowiedzi w naszej witrynie,
            skontaktuj się z nami za pomocą poniższych danych kontaktowych lub
            wypełnij formularz po prawej stronie.{" "}
          </p>
          <p>
            ul. Ogarna 51/52 lok.1
            <br />
            80-826 Gdańsk
          </p>
          <p>
            tel: <a href="tel:+48607202052">+48 607 202 052</a> <br />
            tel: <a href="tel:+48603599714">+48 603 599 714</a> <br />
            e-mail: <a href="mailto:biuro@fifitravel.pl">
              biuro@fifitravel.pl
            </a>{" "}
            <br />
            www: <a href="https://fifitravel.pl">https://fifitravel.pl</a>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default AktywnyPage
